import axios from 'axios';
import React from 'react';
import { FaServer } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import { VscProject } from "react-icons/vsc";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {
  BrowserRouter as Router,
  NavLink, Redirect, Route, Switch
} from "react-router-dom";
import './App.css';
import AdminView from './components/AdminView/AdminView';
import LoginView from './components/LoginView/LoginView';
import MachineView from './components/MachineView/MachineView';
import ProjectView from './components/ProjectView/ProjectView';
import { API_ROOT } from './config';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: true, permissions: [] };
  }

  onAuthenticatedChange(_authenticated) {
    this.reloadPermissions();
  }

  async reloadPermissions() {
    const permissions = await axios.get(API_ROOT + '/permissions');

    this.setState({ loading: false, permissions: permissions.data });
  }

  componentDidMount() {
    this.reloadPermissions();
  }

  async logout() {
    const _req = await axios.post(API_ROOT + '/logout');

    this.onAuthenticatedChange(false);
  }

  render() {
    if (this.state.permissions.length === 0) {
      return (
        <div className="App">
          <LoginView onAuthenticatedChange={(authorized) => this.onAuthenticatedChange(authorized) } />
          <NotificationContainer/>
        </div>
      );
    }

    const can_list_servers = this.state.permissions.find(p => p.permission === 'machine');
    const can_list_projects = this.state.permissions.find(p => p.permission === 'project');
    const can_admin = this.state.permissions.find(p => p.permission === 'admin');

    let default_path = '';
    if (can_list_servers) {
      default_path = '/machines';
    }
    else if (can_list_projects) {
      default_path = '/projects'
    }
    else if (can_admin) {
      default_path = '/admin';
    }

    return (
      <div className="App">
        <Router>
          <nav>
            <ul>
              { can_list_servers && (
                <NavLink exact activeClassName="active" to="/machines">
                  <li>
                    <FaServer /> Servers
                  </li>
                </NavLink>
              ) }

              { can_list_projects && (
                <NavLink exact activeClassName="active" to="/projects">
                  <li>
                    <VscProject /> Projects
                  </li>
                </NavLink>
              ) }

              { can_admin && (
                <NavLink exact activeClassName="active" to="/admin">
                  <li>
                    <FiSettings /> Admin
                  </li>
                </NavLink>
              ) }

              <button className="logout"
                      onClick={this.logout.bind(this)}>
                <li>
                  <IoMdLogOut /> Logout
                </li>
              </button>
            </ul>
          </nav>

          <Switch>
            { default_path && (
              <Route exact path="/">
                <Redirect from='/' to={default_path} />
              </Route>
            ) }

            <Route path="/machines">
              <MachineView />
            </Route>

            <Route path="/projects">
              <ProjectView />
            </Route>

            <Route path="/admin">
              <AdminView reloadPermissions={this.reloadPermissions.bind(this)} />
            </Route>
          </Switch>
        </Router>

        <NotificationContainer/>
      </div>
    );
  }
}

export default App;