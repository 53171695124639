import axios from 'axios';
import React from 'react';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { API_ROOT } from '../../config';
import { renderError } from '../../utils';
import ProjectFileTree from './ProjectFileTree';
import './ProjectView.css';

function flatten_project_tree(project_tree) {

  const by_project = [];

  for (const project of Object.keys(project_tree)) {
    const files = [];
    for (const fileName of Object.keys(project_tree[project])) {
      const data = project_tree[project][fileName];
      files.push( Object.assign({ path: fileName }, data) );
    }

    by_project.push({ name: project, empty: files.length === 0, files: files });
  }

  return by_project;
}

class ProjectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { projects: {}, updating: true, errorMessage: null };
    this.expanded = {};
  }

  async componentDidMount() {
    try {
      const projects = await axios.get(API_ROOT + '/projects');

      this.setState({ projects: projects.data, updating: false });
    }
    catch (error) {
      const errorMessage = renderError(error, 'listing projects');
      this.setState({ errorMessage: errorMessage, updating: false });
    }
  }

  toggleExpanded(ev, project) {
    const el = ev.currentTarget.parentElement;
    let wasExpanded = el.classList.contains('expanded');


    if (!wasExpanded && project.empty) {
      return;
    }

    el.classList.remove('expanded');
    el.classList.remove('collapsed');

    if (wasExpanded) {
      el.classList.add('collapsed');
    }
    else {
      el.classList.add('expanded');
    }

    this.expanded[project.name] = !wasExpanded;
  }

  render() {
    const flattened = flatten_project_tree(this.state.projects);
    if (this.state.updating) {
      return (
        <div className="ProjectView">
          <div className="view-header">
            Loading projects...
          </div>
        </div>
      );
    }

    if (this.state.errorMessage && (flattened.length === 0)) {
        return (
          <div className="ProjectView">
            <div className="view-header error-message">
              {this.state.errorMessage}
            </div>
          </div>
        );
    }

    return (
      <div className="ProjectView">
        <div className="view-header">
          {flattened.length} projects
        </div>

        { flattened.map(p => (
          <div className={"project "  + (this.expanded[p.name] ? 'expanded' : 'collapsed')
                           + (p.empty ? ' empty' : '') }
               key={p.name}>
            <div role="button" className="project-header"
                 onClick={(ev) => this.toggleExpanded(ev, p)}>
              <div className="project-expand-icon collapsed-icon">
                <MdExpandMore />
              </div>
              <div className="project-expand-icon expanded-icon">
                <MdExpandLess />
              </div>
              <div className="project-name">{ p.name }</div>
              <div className="file-count">{ p.files.filter(f => f.action !== 'delete').length } files</div>
            </div>
            <div className="files">
              <ProjectFileTree files={p.files} />
            </div>
          </div>
        )) }
      </div>
    );
  }
}

export default ProjectView;
