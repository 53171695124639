import React from 'react';
import './ScaleMachineConfirmationDialog.css';



class ScaleMachineConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      matching: false,
      selected: props.current_scale,
      is_g4: props.current_scale.startsWith('g4dn'),
      is_g5: props.current_scale.startsWith('g5'),
    };
  }

  onCancel(ev) {
    this.props.on_cancel();
  }

  ignore(ev) {
    ev.stopPropagation();
  }

  confirm() {
    this.props.on_confirm(this.state.selected);
  }

  onMachineNameChange(ev) {
    const value = ev.target.value;

    const matching = value.toLowerCase().trim() === this.props.machine_name.toLowerCase().trim();

    this.setState({ matching: matching });
  }

  render() {
    return (
      <div onClick={ this.onCancel.bind(this) } className="ScaleMachineConfirmationDialogOverlay">
        <div  onClick={ this.ignore.bind(this) } className="ScaleMachineConfirmationDialog">
          <div>
            Confirm that you want to resize this machine.
          </div>

          { this.state.is_g4 && 
          <div className="scale-options">
            <div className="options-name">Select Size:</div>
            <div className="value">
              <input type="radio" name="scale" value="g4dn.xlarge" id="g4dn.xlarge"
                    onClick={() => this.setState({selected: "g4dn.xlarge"})}
                    checked={this.state.selected === "g4dn.xlarge" } />
              <label for="g4dn.xlarge">g4dn.xlarge (16G)</label>

              {this.props.current_scale === "g4dn.xlarge" && <span className="current-scale">Current size</span> }
            </div>

            <div className="value">
              <input type="radio" name="scale" value="g4dn.2xlarge" id="g4dn.2xlarge"
                    onChange={() => this.setState({selected: "g4dn.2xlarge"})}
                    checked={this.state.selected === "g4dn.2xlarge" } />
              <label for="g4dn.2xlarge">g4dn.2xlarge (32G)</label>

              {this.props.current_scale === "g4dn.2xlarge" && <span className="current-scale">Current size</span> }
            </div>

            <div className="value">
              <input type="radio" name="scale" value="g4dn.4xlarge" id="g4dn.4xlarge"
                    onChange={() => this.setState({selected: "g4dn.4xlarge"})}
                    checked={this.state.selected === "g4dn.4xlarge" } />
              <label for="g4dn.4xlarge">g4dn.4xlarge (64G)</label>

              {this.props.current_scale === "g4dn.4xlarge" && <span className="current-scale">Current size</span> }
            </div>

            <div className="value">
              <input type="radio" name="scale" value="g4dn.8xlarge" id="g4dn.8xlarge"
                    onChange={() => this.setState({selected: "g4dn.8xlarge"})}
                    checked={this.state.selected === "g4dn.8xlarge" } />
              <label for="g4dn.8xlarge">g4dn.8xlarge (128G)</label>

              {this.props.current_scale === "g4dn.8xlarge" && <span className="current-scale">Current size</span> }
            </div>

            {/*
            <div className="value">
                <input type="radio" name="scale" value="g4dn.16xlarge" id="g4dn.16xlarge"
                    onChange={() => this.setState({selected: "g4dn.8xlarge"})}
                    checked={this.state.selected === "g4dn.8xlarge" } />
                <label for="g4dn.16xlarge">g4dn.16xlarge (256G)</label>
                {this.props.current_scale === "g4dn.16xlarge" && <span className="current-scale">Current size</span> }
            </div>
            */ }
          </div>
          }

          { this.state.is_g5 && 
          <div className="scale-options">
            <div className="options-name">Select Size:</div>
            <div className="value">
              <input type="radio" name="scale" value="g5.xlarge" id="g5.xlarge"
                    onClick={() => this.setState({selected: "g5.xlarge"})}
                    checked={this.state.selected === "g5.xlarge" } />
              <label for="g5.xlarge">g5.xlarge (16G)</label>

              {this.props.current_scale === "g5.xlarge" && <span className="current-scale">Current size</span> }
            </div>

            <div className="value">
              <input type="radio" name="scale" value="g5.2xlarge" id="g5.2xlarge"
                    onChange={() => this.setState({selected: "g5.2xlarge"})}
                    checked={this.state.selected === "g5.2xlarge" } />
              <label for="g5.2xlarge">g5.2xlarge (32G)</label>

              {this.props.current_scale === "g5.2xlarge" && <span className="current-scale">Current size</span> }
            </div>

            <div className="value">
              <input type="radio" name="scale" value="g5.4xlarge" id="g5.4xlarge"
                    onChange={() => this.setState({selected: "g5.4xlarge"})}
                    checked={this.state.selected === "g5.4xlarge" } />
              <label for="g5.4xlarge">g5.4xlarge (64G)</label>

              {this.props.current_scale === "g5.4xlarge" && <span className="current-scale">Current size</span> }
            </div>

            <div className="value">
              <input type="radio" name="scale" value="g5.8xlarge" id="g5.8xlarge"
                    onChange={() => this.setState({selected: "g5.8xlarge"})}
                    checked={this.state.selected === "g5.8xlarge" } />
              <label for="g5.8xlarge">g5.8xlarge (128G)</label>

              {this.props.current_scale === "g5.8xlarge" && <span className="current-scale">Current size</span> }
            </div>

            {/*
            <div className="value">
                <input type="radio" name="scale" value="g5.16xlarge" id="g5.16xlarge"
                    onChange={() => this.setState({selected: "g5.8xlarge"})}
                    checked={this.state.selected === "g5.8xlarge" } />
                <label for="g5.16xlarge">g5.16xlarge (256G)</label>
                {this.props.current_scale === "g5.16xlarge" && <span className="current-scale">Current size</span> }
            </div>
            */ }
          </div>
          }

          <div className="confirmation-section">
            <div className="explanation">Enter here the machine name: <span className="machine-to-stop">{ this.props.machine_name } </span></div>
            <input type="text" onKeyUp={ this.onMachineNameChange.bind(this) } />
          </div>

          <div>
            <button onClick={() => this.onCancel()} className="cancel-button">Cancel</button>
            <button onClick={() => this.confirm()}  className="confirm-button"
             disabled={!this.state.matching}>Confirm {this.state.matching}</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ScaleMachineConfirmationDialog;
