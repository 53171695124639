import React from 'react';
import { AiFillStar } from "react-icons/ai";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import './ProjectView.css';


function toFileTree(fileList) {
    const tree = { path: '', files: {}, directories: {}, assets: {}};

    for (const file of fileList) {
        if (file.action === 'delete') {
            continue;
        }

        const chunks = file.path.split('/');
        let node = tree;

        // Find the node in the tree where to insert
        for (let i = 0; i < (chunks.length - 1); i++) {
            if (!node.directories[chunks[i]]) {
                node.directories[chunks[i]] = {
                    path: chunks.filter((_, index) => index <= i).join('/') ,
                    files: {},
                    directories: {},
                 };
            }
            node = node.directories[chunks[i]];
        }

        // Insert the file
        node.files[chunks[chunks.length - 1]] = file;
    }

    return tree;
}

function dictmap(dict, f) {
    const result = [];

    for (const key of Object.keys(dict)) {
        result.push(f(key, dict[key]));
    }

    return result;
}

class ProjectFileTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: {} };
        this.expanded = {};
    }

    renderFiles(files) {
        return dictmap(files, (fname, fprops) => (
            <div className="file">
                <div className="field name">{fname}</div>
                {/*
                <div className="field revision">{fprops.rev}</div>
                <div className="field change">{fprops.change}</div>
                <div className="field action">{fprops.action}</div>
                <div className="field type">{fprops.type}</div>
                <div className="field time">{fprops.time}</div>
                 */}
            </div>
        ) );
    }

    toggleExpansion(path) {
        this.state.expanded[path] = !this.state.expanded[path];
        this.setState({ expanded: this.state.expanded });
    }

    markAsAsset(ev, path) {
        ev.stopPropagation();

        console.log("Mark as asset");
    }

    renderDirectories(directories) {
        return dictmap(directories, (name, contents) => (
            <div className={"directory " + (this.state.expanded[contents.path] ? "expanded" : "collapsed")}  >
                <div className="directory-header" onClick={() => this.toggleExpansion(contents.path)}>
                    <div className="directory-expand-icon collapsed-icon">
                        <MdExpandMore />
                    </div>
                    <div className="directory-expand-icon expanded-icon">
                        <MdExpandLess />
                    </div>

                    <div className="directory-name">{ name }</div>

                    <div className="directory-actions">
                        <div className="action make-asset"
                             onClick={ (ev) => this.markAsAsset(ev, contents.path) }>
                            <AiFillStar />
                            <span className="action-description">
                                Mark as asset
                            </span>
                        </div>
                    </div>
                </div>
                <div className="directory-contents">
                    {this.renderNode( contents )}
                </div>
            </div>
        ) );
    }

    renderNode(node) {
        return (
            <div className="file-tree-node">
                <div className="files">
                    { this.renderFiles(node.files) }
                </div>
                <div className="directories">
                    { this.renderDirectories(node.directories) }
                </div>
            </div>
        );
    }

    render() {
        const tree = toFileTree(this.props.files);
        return (
            <div className="file-tree">
                { this.renderNode(tree) }
            </div>
        );
    }
}

export default ProjectFileTree;