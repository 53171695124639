import React from 'react';
import './StopMachineConfirmationDialog.css';



class StopMachineConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { matching: false };
  }

  onCancel(ev) {
    this.props.on_cancel();
  }

  ignore(ev) {
    ev.stopPropagation();
  }

  confirm() {
    this.props.on_confirm();
  }

  onMachineNameChange(ev) {
    const value = ev.target.value;

    const matching = value.toLowerCase().trim() === this.props.machine_name.toLowerCase().trim();

    this.setState({ matching: matching });
  }

  render() {
    return (
      <div onClick={ this.onCancel.bind(this) } className="StopMachineConfirmationDialogOverlay">
        <div  onClick={ this.ignore.bind(this) } className="StopMachineConfirmationDialog">
          <div>
            Confirm that you want to stop this machine
          </div>

          <div className="confirmation-section">
            <div className="explanation">Enter here the machine name: <span className="machine-to-stop">{ this.props.machine_name } </span></div>
            <input type="text" onKeyUp={ this.onMachineNameChange.bind(this) } />
          </div>

          <div>
            <button onClick={() => this.onCancel()} className="cancel-button">Cancel</button>
            <button onClick={() => this.confirm()}  className="confirm-button"
             disabled={!this.state.matching}>Confirm {this.state.matching}</button>
          </div>
        </div>
      </div>
    );
  }
}

export default StopMachineConfirmationDialog;
