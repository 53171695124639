import axios from 'axios';
import React from 'react';
import { FaServer } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { GrStatusUnknown } from "react-icons/gr";
import { IoMdClose, IoMdResize } from "react-icons/io";
import { MdSave } from "react-icons/md";
import { RiEditFill } from "react-icons/ri";
import { VscProject } from "react-icons/vsc";
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { API_ROOT } from '../../config';
import { renderError } from '../../utils';
import { get_instance_list } from '../MachineView/MachineView';
import './AdminView.css';

const MIN_USER_LENGTH = 5;
const MIN_PASSWORD_LENGTH = 5;

function deepClone(jsonifiable) {
  return JSON.parse(JSON.stringify(jsonifiable));
}

function unixMsToStr(ms_timestamp, options) {
  const date = new Date(ms_timestamp);

  if (!options) {
      options = {};
  }

  const left_pad = ((val, target_length, pad_character) => {
      let str = val.toString();

      while (str.length < target_length) {
          str = pad_character + str;
      }
      return str;
  });
  const pad02 = (val) => {
      return left_pad(val, 2, '0');
  }

  let result = (`${date.getFullYear()}/${pad02(date.getMonth() + 1)}/${pad02(date.getDate())} `
                + ` - ${pad02(date.getHours())}:${pad02(date.getMinutes())}:${pad02(date.getSeconds())}`);

  if (options.ms_precision) {
      result += `.${date.getMilliseconds()}`;
  }
  return result;
}


class AdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      create_user_form: {},
      updating: true,
      creating_user: false,
      editing_users: {},
      user_edits: {},
      instances: [],
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.updateUserList();
    this.updateInstanceList();
  }

  async updateUserList() {
    try {
      const users = await axios.get(API_ROOT + '/users');

      this.setState({ users: users.data, updating: false });
    }
    catch(error) {
      const errorMessage = renderError(error, 'listing users');
      this.setState({ errorMessage: errorMessage, updating: false });
    }
  }

  async updateInstanceList() {
    try {
      const instances = await get_instance_list();
      this.setState({ instances: instances.data });
    }
    catch(error) {
      const errorMessage = renderError(error, 'listing machines');
      this.setState({ errorMessage: errorMessage });
    }
  }

  handleCreateUserFormChange(field, ev) {
    const value = ev.target.value;

    const data = this.state.create_user_form;
    data[field] = value;

    const ready = ((data.username && data.username.length >= MIN_USER_LENGTH)
                && (data.password && data.password.length >= MIN_PASSWORD_LENGTH)
                && (data['confirm-password'] === data.password));

    data._ready = ready;
    this.setState({ create_user_form: data });
  }

  async submitCreateUser(ev) {
    ev.preventDefault();

    try {
      const _req = await axios.post(API_ROOT + '/users', {
        username: this.state.create_user_form.username,
        password: this.state.create_user_form.password,
      });

      await this.updateUserList();
      this.setState({ creating_user: false });
    }
    catch(error) {
      const errorMessage = renderError(error, 'creating user');
      this.setState({ errorMessage: errorMessage });
    }
  }

  render_permission(user, permission) {
    let icon;

    switch (permission.permission) {
      case 'admin':
        icon = ( <FiSettings /> );
        break;

      case 'machine':
        icon = ( <FaServer /> );
        break;

      case 'project':
        icon = ( <VscProject /> );
        break;

      case 'scale':
        icon = ( <IoMdResize /> );
        break;

      default:
        console.error("Unknown permission: " + JSON.stringify(permission));
        icon = ( <GrStatusUnknown /> );
    }

    return (
      <div key={permission.permission + permission.scope} className={"permission permission-" + permission.permission}>
        {icon}
      </div>
    );
  }

  toggleUserEditPermission(user, permission) {
    const edits = this.state.user_edits;
    const edit = edits[user.id];

    const idx = edit.permissions.findIndex(p => p.permission === permission);

    if (idx < 0) {
      edit.permissions.push({ permission: permission, scope: 'all' });
    }
    else {
      edit.permissions.splice(idx, 1);
    }

    this.setState({ user_edits: edits });
  }

  show_permission_scope(permissions, permission_name) {
    const applicable = permissions.filter(p => p.permission === permission_name);
    let string;

    if (applicable.length === 0) {
      string = "No";
    }
    else if (applicable.find(p => p.scope === 'all')) {
      string = 'All';
    }
    else {
      string = applicable.length;
    }

    return (<span className="permission-scope"> { string } </span>);
  }

  show_editable_machine_permission_scope(permissions, permission_name) {
    const options = [];
    for (const instance of this.state.instances) {
      if (!instance.is_production) {
        continue;
      }

      options.push({
        value: instance.id,
        label: instance.name || instance.id
      });
    }


    let defaultSelection = [];
    for (const perm of permissions) {
      if (perm.permission !== permission_name) {
        continue
      }

      if (perm.scope === 'all') {
        defaultSelection = options;
      }
      else {
        defaultSelection.push({
          value: perm.scope,
          label: (options.find(p => p.value === perm.scope) || { label: '???' }).label
         })
      }
    }

    const setSelectedOption = (values, event) => {
      console.log("values", values);
      console.log("event", event);
      for (let i = 0; i < permissions.length;) {
        const perm = permissions[i];

        console.log(perm.permission !== permission_name);
        if (perm.permission !== permission_name) {
          i++;
          continue;
        }

        permissions.splice(i, 1);
      }

      for (const v of values) {
        permissions.push({
          permission: permission_name,
          scope: v.value,
        });
      }
    }

    return (
      <span className="permission-scope">
        <Select isMulti
          className="scope-multi-select"
          classNamePrefix="react-select"
          defaultValue={defaultSelection}
          onChange={setSelectedOption}
          options={options} />
      </span>
    );
  }

  render_user_permissions(user) {
    if (!this.state.editing_users[user.id]) {
      return (
        <div className='viewing'>
          <div className={"permission permission-admin "
                        + (user.permissions.find(p => p.permission === 'admin') ? 'enabled' : 'disabled')
                        }
               title="Admin. users">
            { this.show_permission_scope(user.permissions, 'admin') }
            <FiSettings />
          </div>
          <div className={"permission permission-machine "
                        + (user.permissions.find(p => p.permission === 'machine') ? 'enabled' : 'disabled')
                        }
                title="Machines">
            { this.show_permission_scope(user.permissions, 'machine') }
            <FaServer />
          </div>
          <div className={"permission permission-project "
                        + (user.permissions.find(p => p.permission === 'project') ? 'enabled' : 'disabled')
                        }
              title="Projects">
            { this.show_permission_scope(user.permissions, 'project') }
            <VscProject />
          </div>
          <div className={"permission permission-scale "
                        + (user.permissions.find(p => p.permission === 'scale') ? 'enabled' : 'disabled')
                        }
              title="Scale machines">
            { this.show_permission_scope(user.permissions, 'scale') }
            <IoMdResize />
          </div>
        </div>
      )
    }

    const edit = this.state.user_edits[user.id];

    return (
      <div className='editing'>
        <div className={"permission permission-admin "
                       + (edit.permissions.find(p => p.permission === 'admin') ? 'enabled' : 'disabled')
                       }
             onClick={(_ev) => this.toggleUserEditPermission(user, 'admin')}
             title="Admin. users">
          { this.show_permission_scope(edit.permissions, 'admin') }
          <FiSettings />
        </div>
        <div className={"permission permission-machine "
                       + (edit.permissions.find(p => p.permission === 'machine') ? 'enabled' : 'disabled')
                       }
             title="Machines">
          { this.show_editable_machine_permission_scope(edit.permissions, 'machine') }
          <FaServer />
        </div>
        <div className={"permission permission-project "
                       + (edit.permissions.find(p => p.permission === 'project') ? 'enabled' : 'disabled')
                       }
             onClick={(_ev) => this.toggleUserEditPermission(user, 'project')}
             title="Projects">
          { this.show_permission_scope(edit.permissions, 'project') }
          <VscProject />
        </div>
        <div className={"permission permission-scale "
                       + (edit.permissions.find(p => p.permission === 'scale') ? 'enabled' : 'disabled')
                       }
             onClick={(_ev) => this.toggleUserEditPermission(user, 'scale')}
             title="Scale machines">
          { this.show_permission_scope(edit.permissions, 'scale') }
          <IoMdResize />
        </div>
      </div>
    );
  }

  toggleEditingUser(user) {
    if (this.state.instances.length === 0) {
      NotificationManager.warning('Machine list not loaded yet', 'Wait to edit user', 5000);
      return;
    }

    const data = this.state.editing_users;
    data[user.id] = !data[user.id];

    const user_edits = this.state.user_edits;
    if (data[user.id]) {
      // Editing
      user_edits[user.id] = { permissions: deepClone(user.permissions) };
    }
    else {
      delete user_edits[user.id];
    }

    this.setState({ editing_users: data, user_edits: user_edits });
  }

  async saveEditingUser(user) {
    const userEdit = this.state.user_edits[user.id];

    delete this.state.editing_users[user.id];
    delete this.state.user_edits[user.id];

    this.setState({ editing_users: this.state.editing_users, user_edit: this.state.user_edits });

    try {
      const _req = await axios.post(API_ROOT + '/users/' + user.id + '/permissions', {permissions: userEdit.permissions});
      await this.updateUserList();

      if (this.props.reloadPermissions) {
        this.props.reloadPermissions();
      }
    }
    catch(error) {
      const errorMessage = renderError(error, 'updating user permissions');
      this.setState({ errorMessage: errorMessage, updating: false });
    }
  }

  render() {
    if (this.state.updating) {
      return (
        <div className="AdminView">
          Loading...
        </div>
      );
    }

    if (this.state.errorMessage && (this.state.users.length === 0)) {
      return (
        <div className="AdminView">
          <div className="view-header error-message">
            {this.state.errorMessage}
          </div>
        </div>
      );
    }

    let add_user_section;
    if (this.state.creating_user) {
      add_user_section = (
        <form className={"add-user-section "} onSubmit={(ev) => this.submitCreateUser(ev)}>
          <h3>New user</h3>
          <button className="close-panel-button"
              onClick={ () => this.setState({ creating_user: false }) }
              ><IoMdClose /></button>

          <div className="form-field">
            <label htmlFor="create-user-name">Username: </label>
            <input name="create-user-name" id="create-user-name" type="text"
                  onChange={(ev) => this.handleCreateUserFormChange('username', ev) }
                  placeholder="Enter username" />
          </div>

          <div className="form-field">
            <label htmlFor="create-user-password">Password: </label>
            <input name="create-user-password" id="create-user-password" type="password"
                  onChange={(ev) => this.handleCreateUserFormChange('password', ev) }
                  placeholder="Enter password" />
          </div>

          <div className="form-field">
            <label htmlFor="create-user-confirm-password">Confirm: </label>
            <input name="create-user-confirm-password" id="create-user-confirm-password" type="password"
                  onChange={(ev) => this.handleCreateUserFormChange('confirm-password', ev) }
                  placeholder="Confirm password" />
          </div>

          <input type="submit"
                 disabled={ !this.state.create_user_form._ready }
                 className="create-user-button" value="Create user" />
        </form>
      );
    }
    else {
      add_user_section = (
        <button className="add-user-button"
        onClick={() => this.setState({ creating_user: true, create_user_form: { _ready: false } })}
        >New user</button>
      );
    }

    return (
      <div className="AdminView">
        <section>
          {this.state.users.length} users
          <table>
            <thead>
              <tr>
                <th className="field id">
                  ID
                </th>
                <th className="field name">
                  Name
                </th>
                <th className="field permissions">
                  Permissions
                </th>
                <th className="field actions">
                  Actions
                </th>
                <th className="field registration-time">
                  Registration time
                </th>
              </tr>
            </thead>
            <tbody>
            { this.state.users.map(u => (
                    <tr key={u.id} className="user">
                      <td className="field id">{u.id}</td>
                      <td className="field name">{u.name}</td>
                      <td className="field permissions">
                        { this.render_user_permissions(u) }
                      </td>
                      <td className="field actions">
                        { !this.state.editing_users[u.id] && (
                          <button className="edit"
                            onClick={() => this.toggleEditingUser(u)}>
                            <RiEditFill />
                          </button>
                        ) }
                        { this.state.editing_users[u.id] && (
                          <button className="save"
                          onClick={() => this.saveEditingUser(u)}>

                            <MdSave />
                          </button>
                        )}
                      </td>
                      <td className="field registration-time">
                        {unixMsToStr(u.registration_time * 1000, {ms_precision: false})}
                      </td>
                    </tr>
                  )) }
            </tbody>
          </table>
        </section>

        {add_user_section}
      </div>
    );
  }
}

export default AdminView;
