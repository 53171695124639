import { NotificationManager } from 'react-notifications';

export function renderError(error, action) {
    let message;
    if (error.response) {
        message = error.response.statusText;
    }

    NotificationManager.error(message || (error + ''), 'Error ' + (action || ''), 5000);

    return message || (error + '');
}