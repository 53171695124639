import axios from 'axios';
import React from 'react';
import { API_ROOT } from '../../config';
import { renderError } from '../../utils';
import StopMachineConfirmationDialog from '../StopMachineConfirmationDialog/StopMachineConfirmationDialog';
import ScaleMachineConfirmationDialog from '../ScaleMachineConfirmationDialog/ScaleMachineConfirmationDialog';
import './MachineView.css';
import { IoMdResize } from 'react-icons/io';

const SLEEP_TIME = 10 * 1000;

export function get_instance_list() {
  return axios.get(API_ROOT + '/instances');
}

class MachineView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instances: [],
      updating: false,
      errorMessage: null,
      confirmDeletion: null,
      confirmScale: null,
      canAdmin: false,
      canScale: false,
    };
    this.timerId = null;
    this.stopped = false;
  }

  async update() {
    const instances = await get_instance_list();

    const data = instances.data
      .filter(instance => instance.is_vfx_relevant)
      .map(instance => {
      if (instance.state_code === 16) { // Running
        // Take a look at checks
        if (instance.checks_passed > 0 && instance.checks_failed === 0) {
          instance.instance_state_code = 'ready';
        }
        else {
          instance.instance_state_code = 'init-pending';
          instance.state = 'pending';
        }
      }
      else {
        instance.instance_state_code = instance.state_code;
      }

      return instance;
    })

    this.setState({ instances: data });
  }

  componentDidMount() {
    const triggerUpdate = () => {
      this.setState({ updating: true }, () => {
        this.update().then(() => {
          this.setState({ updating: false });
        }).catch((error) => {
            const errorMessage = renderError(error, 'listing machines');
            this.setState({ errorMessage: errorMessage, updating: false });
        }).then(() => {
            if (!this.stopped) {
                this.timerId = setTimeout(triggerUpdate, SLEEP_TIME);
            }
        });
      });
    };

    triggerUpdate();

    axios.get(API_ROOT + '/permissions').then(
      (permissions) => {
        const can_admin = permissions.data.find(p => p.permission === 'admin');
        const can_scale = permissions.data.find(p => p.permission === 'scale');
        this.setState({canAdmin: can_admin, canScale: can_admin || can_scale });
      });
  }

  componentWillUnmount() {
      this.stopped = true;
      clearTimeout(this.timerId);
  }

  async startInstance(instance) {
    console.log("Starting", instance);
    this.setState({ instances: this.state.instances.map(i => {
      if (i.id === instance.id) {
        i.state_code = 0;
      }

      return i;
    }) })
    try {
      const response = await axios.post(API_ROOT + '/instances/' + instance.id + '/start');
      console.log("R", response);
      this.update();
    }
    catch (error) {
      const _errorMessage = renderError(error, 'starting machine');
    }
  }

  cancelStop() {
    console.log("Machine stop cancelled");
    this.setState({ confirmDeletion: null });
  }

  cancelScale() {
    console.log("Machine scale cancelled");
    this.setState({ confirmScale: null });
  }

  async confirmStop() {
    const instance_id = this.state.confirmDeletion.machine_id;

    this.setState({
      confirmDeletion: null,
      instances: this.state.instances.map(i => {
        if (i.id === instance_id) {
          i.state_code = 0;
        }

        return i;
      })
    });

    try {
      const response = await axios.post(API_ROOT + '/instances/' + instance_id + '/stop');
      console.log("R", response);
      this.update();
    }
    catch (error) {
      const _errorMessage = renderError(error, 'stopping machine');
    }
  }

  async confirmScale(new_scale) {
    const instance_id = this.state.confirmScale.machine_id;

    this.setState({
      confirmScale: null,
      instances: this.state.instances.map(i => {
        if (i.id === instance_id) {
          i.state_code = 0;
        }

        return i;
      })
    });

    try {
      const response = await axios.post(API_ROOT + '/instances/' + instance_id + '/scale',
                                        { scale: new_scale });
      console.log("R", response);
      this.update();
    }
    catch (error) {
      const _errorMessage = renderError(error, 'scaling machine');
    }
  }

  async stopInstance(instance) {
    console.log("Stopping", instance);
    this.setState({
      confirmDeletion: {
        machine_name: instance.name,
        on_cancel: this.cancelStop.bind(this),
        on_confirm: this.confirmStop.bind(this),
        machine_id: instance.id,
      }
    });

  }

  async startScaleInstance(instance) {
    console.log("Scaling", instance);
    this.setState({
      confirmScale: {
        machine_name: instance.name,
        on_cancel: this.cancelScale.bind(this),
        on_confirm: this.confirmScale.bind(this),
        machine_id: instance.id,
        current_scale: instance.type,
      }
    });

  }

  render() {
      if (this.state.errorMessage && (this.state.instances.length === 0)) {
        return (
          <div className="MachineView">
            <div className="view-header error-message">
              {this.state.errorMessage}
            </div>
          </div>
        );
    }

    return (
      <div className="MachineView">
            { this.state.instances.filter(i => i.state_code !== 48).length } machines &nbsp;
            <span className={ "updating-marker " + (this.state.updating ? 'visible' : 'hidden') }>
        [updating]
         </span>

        <table>
          <tbody>
            <tr>
              <th>State</th>
              <th>Name</th>
              <th>Type</th>
              <th>IP</th>
              <th></th>
            </tr>
        { this.state.instances.map(instance => (
          <tr key={instance.id} className={ "instance instance-state-" + instance.instance_state_code } >
            <td className="state">{ instance.state }</td>
            <td className="name">{ instance.name }</td>
            <td className="type">{ instance.type }</td>
            <td className="ip">{ instance.ip }</td>
            <td className="actions">
              { instance.state_code === 80 && instance.can_operate && // Stopped instances can be started
                <button className="start-instance-button" onClick={() => this.startInstance(instance)}>
                  Start
                </button>
              }

              { (instance.instance_state_code === 'ready' || instance.instance_state_code === 'init-pending') && instance.can_operate &&
                <button className="stop-instance-button" onClick={() => this.stopInstance(instance)}>
                  Shutdown
                </button>
              }

              { this.state.canScale && (instance.type.startsWith('g4dn.')  || instance.type.startsWith('g5') ) && instance.state_code === 80 && instance.can_operate &&
                <button className="scale-instance-button" onClick={() => this.startScaleInstance(instance)}>
                  <IoMdResize /> Resize
                </button>
              }

              { (instance.instance_state_code === 'ready' || instance.instance_state_code === 'init-pending') && instance.can_operate &&
                <a href={ `https://${instance.ip}:8443` } rel="noreferrer noopener" target="_blank" >DCV</a>
              }
            </td>
          </tr>
        )) }
        </tbody>
        </table>
        { this.state.confirmDeletion &&
           <StopMachineConfirmationDialog
              machine_name={ this.state.confirmDeletion.machine_name }
              on_cancel={ this.state.confirmDeletion.on_cancel }
              on_confirm={ this.state.confirmDeletion.on_confirm }
           />
        }
        { this.state.confirmScale &&
           <ScaleMachineConfirmationDialog
              machine_name={ this.state.confirmScale.machine_name }
              on_cancel={ this.state.confirmScale.on_cancel }
              on_confirm={ this.state.confirmScale.on_confirm }
              current_scale={ this.state.confirmScale.current_scale }
           />
        }
      </div>
    );
  }
}

export default MachineView;
