import axios from 'axios';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { API_ROOT } from '../../config';
import { renderError } from '../../utils';
import './LoginView.css';


const MIN_USER_LENGTH = 5;
const MIN_PASSWORD_LENGTH = 5;

class LoginView extends React.Component {
  static propTypes = {
    onAuthenticatedChange: (authorized) => {},
  };

  constructor(props) {
    super(props);

    this.state = { login_user_form: {} };
  }

  handleLoginUserFormChange(field, ev) {
    const value = ev.target.value;

    const data = this.state.login_user_form;
    data[field] = value;

    const ready = ((data.username && data.username.length >= MIN_USER_LENGTH)
                && (data.password && data.password.length >= MIN_PASSWORD_LENGTH));

    data._ready = ready;
    this.setState({ login_user_form: data });
  }

  async submitLogin(ev) {
    ev.preventDefault();

    try {
      const req = await axios.post(API_ROOT + '/login', {
        username: this.state.login_user_form.username,
        password: this.state.login_user_form.password,
      });

      this.props.onAuthenticatedChange(req.data.success);
      NotificationManager.success('Login successfull');
    }
    catch (error) {
      renderError(error);
    }
  }

  render() {
    return (
      <div className="LoginView">
        <form className="login-user-section" onSubmit={(ev) => this.submitLogin(ev)}>
          <h3>Login</h3>

          <div className="form-field">
            <label htmlFor="login-user-name">Username: </label>
            <input name="login-user-name" id="login-user-name" type="text"
                  onChange={(ev) => this.handleLoginUserFormChange('username', ev) }
                  placeholder="Enter username" />
          </div>

          <div className="form-field">
            <label htmlFor="login-user-password">Password: </label>
            <input name="login-user-password" id="login-user-password" type="password"
                  onChange={(ev) => this.handleLoginUserFormChange('password', ev) }
                  placeholder="Enter password" />
          </div>

          <input type="submit"
                 disabled={ !this.state.login_user_form._ready }
                 className="login-user-button" value="Login user" />
        </form>
      </div>
    );
  }
}

export default LoginView;
